import React from "react";
import SEO from "../../components/seo";
import Blog from "../../components/Blog";
import Par from "../../components/Par";
import H1 from "../../components/h1";
import SiteLink from "../../components/SiteLink";

const title = "Gallstones";

// Download link - http://www.fbrennangastro.com.au/downloads/gesagallstones.pdf

export default () => (
	<Blog title={title}>
		<SEO
			title={title}
			keywords={[title, "gallbladder", "gallstones symptoms", "crohn's disease"]}
			type="article"
			description="Gallstones and information on what they are, what symptoms they cause and how to treat"
		/>
		<H1>{title}</H1>
		<Par>
			Gallstones are small, hard deposits that form in the gallbladder. They do not produce symptoms in most patients.
			In cases where symptoms do occur prompt treatment is required.
		</Par>
		<Par>
			Gallstones become common as we age. In patients over 50 years of age the prevalence is 25-30%. They are more
			common in women, in people who are overweight or lose weight rapidly, and in those with a family history of
			gallstones. They are also more common in people with{" "}
			<SiteLink to="/articles/inflammatory-bowel-disease-IBD-crohns-and-colitis">Crohn&apos;s disease.</SiteLink>
		</Par>
		<Par>
			Gallstones that present no symptoms generally do not interfere with gallbladder, liver or pancreas function and do
			not require treatment.
		</Par>
		<Par>In general, patients who have symptoms will need surgery right away, or after a short period of time.</Par>
	</Blog>
);
